import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Form,
  Select,
  Card,
  Input,
  DatePicker,
  Carousel,
  Badge,
  Calendar,
  Divider,
  Alert,
  Checkbox,
  Radio,
  Space,
  Statistic,
  Popover,
  List,
  Avatar,
  TimePicker,
  Drawer,
} from "antd";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { API_ENDPOINT, LEAVE_CNT, MENU_PATH } from "../config/index";

import { useSelector } from "react-redux";
import {
  betweenDays,
  diffDays,
  getData,
  getFutureDaysOfMonth,
  groupBy,
  postData,
} from "../utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import AdminPage from "./admin";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import LoadingComponent from "../components/loading";

const arrExpenses = {
  fuel_cost: "ค่าน้ำมันเชื้อเพลิง",
  driver_cost: "ค่าเบี้ยเลี้ยงพนักงานขับรถพร้อมเงินสมทบ",
  accommodation_cost: "ค่าที่พัก",
  other_expenses: "ค่าใช้จ่ายอื่น ๆ",
};

const arrLeave = {
  ป่วย: "ป่วย",
  คลอดบุตร: "คลอดบุตร",
  กิจส่วนตัว: "กิจส่วนตัว",
};

const BookingPage = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  let locationPath = location.pathname.slice(1).replace("/" + params.book, "");

  const filterPath = MENU_PATH.filter((x) => x.path === locationPath)[0];

  // console.log(filterPath);
  // const filterSubPath = Object.keys(filterPath).filter(
  //   (x) => x === params.book
  // )[0];

  const userState = useSelector((state) => state.auth.authUser);

  const [formBook] = Form.useForm();

  const carouselRef = useRef(null);
  const [auto, setAuto] = useState(true);
  const [mode, setMode] = useState(1);
  const [wherewith, setWherewith] = useState(
    params.id === "leave" ? "ป่วย" : "true"
  );
  const [expensesAll, setExpensesAll] = useState([]);

  const [types, setTypes] = useState([]);
  const [selectRoom, setSelectRoom] = useState();
  const [lastestSick, setLastestSick] = useState([null, null]);

  const [dataCalendar, setDataCalendar] = useState([]);
  const [modeCalendar, setModeCalendar] = useState("month");
  const [loading, setLoading] = useState(false);
  const [hisState, setHistState] = useState({
    total: 0,
    year: new Date().getFullYear(),
  });
  const [drawerContent, setDrawerContent] = useState({ open: false });
  const [radioDate, setRadioDate] = useState(1);

  const monthCellRender = (value) => {
    const num = true || value.month() === 8 ? 15 : 0;
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>ฟังก์ชั่นนี้อยู่ในระหว่างพัฒนา</span>
      </div>
    ) : null;
  };

  const dateCellRender = (value) => {
    let e = moment(value.valueOf()).startOf("day").valueOf();

    if (dataCalendar[e]) {
      let arrGroup = groupBy(dataCalendar[e], (x) => {
        return x.cr_id;
      });

      return (
        <ul className="flex gap-2 flex-col">
          {Object.keys(arrGroup).map((x, i) => {
            const roomName = types.filter(
              (y) => Number(y.id) === Number(x)
            )?.[0]?.cr_name;

            let divTwo = i % 2 == 0;

            return (
              <li key={i}>
                <Badge
                  status={"error"}
                  text={
                    <span
                      className={
                        (divTwo ? "bg-orange-200" : "bg-blue-200") +
                        " p-1 rounded-sm"
                      }
                    >
                      {roomName + " : " + arrGroup[x].length}
                    </span>
                  }
                />
              </li>
            );
          })}
        </ul>
      );
    } else return null;
  };

  const dateDrawerRender = (value) => {
    let e = moment(value.valueOf()).startOf("day").valueOf();

    if (dataCalendar[e]) {
      let arrGroup = groupBy(dataCalendar[e], (x) => {
        return x.cr_id;
      });

      return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {Object.keys(arrGroup).map((key) =>
            arrGroup[key].map((item, index) => (
              <div key={`${key}-${index}`}>
                <Card
                  title={`ห้อง : ${item.cr_name}`}
                  className="w-full"
                  headStyle={{ background: "#ccc" }}
                  style={{
                    boxShadow:
                      "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                  }}
                >
                  <p>
                    <strong>ผู้จอง : </strong> {item.full_name}
                  </p>
                  <p>
                    <strong>คำอธิบาย : </strong> {item.description}
                  </p>
                  <p>
                    <strong>เวลา : </strong>
                    {moment(Number(item.time_start)).format("HH:mm")}
                    {" - "}
                    {moment(Number(item.time_end)).format("HH:mm")}
                  </p>
                </Card>
              </div>
            ))
          )}
        </div>
      );
    } else return null;
  };

  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  const disabledDate = (current) => {
    return (
      current && current < moment().subtract(1, "days")
      // || current.day() === 0 || current.day() === 6
    );
  };

  const getDataCalendar = async (e) => {
    let selectTime;

    if (e) {
      selectTime = moment(e.$d).valueOf();
    } else {
      const serverTime = await getData(API_ENDPOINT + "/servertime");

      selectTime = serverTime.data;
    }

    const firstDate = moment(selectTime).startOf("month");
    const firstTimestamp = firstDate.valueOf();
    const lastDate = moment(selectTime).endOf("month");
    const lastTimestamp = lastDate.valueOf();

    let projection =
      "NULLIF(CONCAT_WS(' ', a.title, a.firstname, a.middlename, a.lastname), '') AS full_name, ";

    if (params.id === "room") {
      projection += `description, room_name AS cr_name, time_start, time_end, a.user_id, room_id AS cr_id, floor, size`;
    } else if (params.id === "car") {
      projection += `description, car_name AS cr_name, time_start, time_end, a.user_id, car_id AS cr_id`;
    }

    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        table: `booking_a_${params.id} AS x`,
        match: `CAST(time_start AS BIGINT) >=${firstTimestamp} AND CAST(time_end AS BIGINT) <=${lastTimestamp}`,
        projection: projection,
        join: [
          {
            type: "LEFT",
            table: "core_role AS a",
            on: "x.user_id = a.user_id",
          },
          {
            type: "LEFT",
            table: `${params.id} AS b`,
            on: `x.${params.id}_id = b.id`,
          },
        ],
      },
    });

    if (res.data.ok && res.data.message.length > 0) {
      let arrGroup = groupBy(res.data.message, (x) => {
        const first = moment(Number(x.time_start)).startOf("day").valueOf();

        return first;
      });

      setDataCalendar(arrGroup);
    } else {
      console.log(res.data.message);
    }
  };

  const getQuery = async () => {
    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        table: params.id,
        projection:
          params.id === "room"
            ? "id, room_name AS cr_name, floor, size, default_f, image"
            : "id, car_name AS cr_name, image, car_registration",
        sort: `id ASC`,
      },
    });

    if (res.data.ok) {
      return res.data.message;
    } else {
      console.log(res.data.message);
    }
  };

  const getFacility = async () => {
    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        table: "Facility",
        sort: "id ASC",
      },
    });

    return res;
  };

  const setDefaultForm = () => {
    if (params.id === "room") {
      formBook.setFieldsValue({
        description: "",
        how: 1,
        room: "",
        people: "1",
        datepicker: "",
        timepicker: "",
      });
    } else if (params.id === "car") {
      formBook.setFieldsValue({
        description: "",
        location: "",
        agree: "true",
        people: "1",
        travel: 1,
        datepicker: "",
      });
    } else {
      formBook.setFieldsValue({
        address: "",
        leave_name: "ป่วย",
        datepicker: "",
      });
    }
  };

  const getLastLeaveSick = async () => {
    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        table: "leave",
        match: `user_id = '${userState.user_id}' AND type_leave_id = 2 AND one_status = 'true' AND two_status = 'true'`,
        projection: "time_start, time_end",
        sort: `time_start DESC`,
        limit: `1`,
      },
    });

    if (res.data.ok) {
      if (res.data.message.length > 0) {
        const { time_start, time_end } = res.data.message[0];

        const start = moment(Number(time_start)).format("DD/MM/YYYY");
        const end = moment(Number(time_end)).format("DD/MM/YYYY");

        setLastestSick([start, end]);
      } else {
        setLastestSick([null, null]);
      }
    } else {
      console.log(res.data.message);
    }
  };

  const getAllLeaveRelex = async () => {
    const serverTime = await getData(API_ENDPOINT + "/servertime");
    let year = new Date(serverTime.data).getFullYear();

    const res = await postData(API_ENDPOINT + `/db/mis_logistic_nu/select`, {
      query: {
        table: "leave",
        match: `user_id = '${userState.user_id}'\n
        AND one_status = 'true' AND two_status = 'true'\n
        AND type_leave_id = 1\n
        AND EXTRACT(YEAR FROM TO_TIMESTAMP(CAST(time_start AS BIGINT)/1000)) = '${year}'`,
        projection:
          "user_id, SUM( ROUND( CAST(scheduled AS NUMERIC) ) ) AS total",
        group: `user_id`,
      },
    });

    if (res.data.ok) {
      if (res.data.message.length > 0) {
        setHistState(res.data.message[0]);
      } else {
        setHistState({
          total: 0,
          year: new Date(serverTime.data).getFullYear(),
        });
      }
    } else {
      console.log(res.data.message);
    }
  };

  const handleSaveRoom = async () => {
    const serverTime = await getData(API_ENDPOINT + "/servertime");

    let val = formBook.getFieldsValue();

    const chooseDays =
      radioDate === 1
        ? val.datepicker.map((x) => {
            let date = moment(x.$d);

            const dt = val.timepicker.map((y) => {
              let time = moment(y.$d);
              let hours = time.hours();
              let minutes = time.minutes();
              // let seconds = time.seconds();

              date.set({
                hour: hours,
                minute: minutes,
                // second: seconds,
              });

              return date.valueOf();
            });

            return dt;
          })
        : betweenDays(
            val.datepicker[0].$d,
            val.datepicker[1].$d,
            val.timepicker[0].$d,
            val.timepicker[1].$d
          );

    const dupMatch = chooseDays.map((x) => {
      return `(CAST(time_start AS BIGINT) < ${x[1]} AND CAST(time_end AS BIGINT) > ${x[0]})
      OR (CAST(time_start AS BIGINT) >= ${x[0]} AND CAST(time_end AS BIGINT) <= ${x[1]})
      OR (CAST(time_start AS BIGINT) < ${x[1]} AND CAST(time_end AS BIGINT) > ${x[1]})
      OR (CAST(time_start AS BIGINT) < ${x[0]} AND CAST(time_end AS BIGINT) > ${x[0]})
      AND NOT (CAST(time_start AS BIGINT) = ${x[0]} AND ${x[1]} > CAST(time_end AS BIGINT))\n`;
    });

    const dup = await postData(API_ENDPOINT + "/db/mis_logistic_nu/select", {
      query: {
        table: "booking_a_room",
        match: `room_id = ${val.room_id} AND (${dupMatch.join("OR")})`,
        projection: "time_start, time_end",
        sort: "time_start ASC",
      },
    });

    if (dup.data.ok) {
      if (dup.data.message.length === 0) {
        delete val.datepicker;
        delete val.timepicker;

        const objMap = chooseDays.map((x) => {
          let obj = {
            time_start: x[0],
            time_end: x[1],
            ...val,
            user_id: userState.user_id,
            create_at: serverTime.data,
            update_at: serverTime.data,
          };

          return obj;
        });

        const res = await postData(
          API_ENDPOINT + `/db/mis_logistic_nu/insert`,
          {
            insert: objMap,
            table: "booking_a_room",
          }
        );

        if (res.data.ok) {
          setDefaultForm();

          Swal.fire({
            text: "บันทึกสำเร็จ !",
            showCloseButton: true,
            icon: "success",
            showConfirmButton: false,
          });
        } else {
          console.log(res.data.message);
        }
      } else {
        Swal.fire({
          title: `กรุณาเช็ควันเวลาที่คุณใช้งานอีกครั้ง !`,
          showCloseButton: true,
          icon: "error",
          showConfirmButton: false,
          html: `<div className="flex gap-2">
            <div>วันที่ เวลา</div>
            ${dup.data.message.map((x) => {
              let ts = moment(Number(x.time_start)).format("DD/MM/YYYY HH:mm");
              let te = moment(Number(x.time_end)).format("DD/MM/YYYY HH:mm");

              return `<div>${ts} - ${te}</div>`;
            })}
            <div>ถูกจองแล้ว</div>
          </div>`.replaceAll(",", ""),
        });
      }
    } else {
      console.log(dup.data.message);
    }
  };

  const handleSaveCar = async () => {
    const serverTime = await getData(API_ENDPOINT + "/servertime");

    let val = formBook.getFieldsValue();
    let timeStart = moment(val.datepicker[0].$d).valueOf();
    let timeEnd = moment(val.datepicker[1].$d).valueOf();

    const dup = await postData(API_ENDPOINT + "/db/mis_logistic_nu/select", {
      query: {
        table: "booking_a_car",
        match: `car_id = ${val.car_id} AND (CAST(time_start AS BIGINT) < ${timeEnd} AND CAST(time_end AS BIGINT) > ${timeStart})
        OR (CAST(time_start AS BIGINT) >= ${timeStart} AND CAST(time_end AS BIGINT) <= ${timeEnd})
        OR (CAST(time_start AS BIGINT) < ${timeEnd} AND CAST(time_end AS BIGINT) > ${timeEnd})
        OR (CAST(time_start AS BIGINT) < ${timeStart} AND CAST(time_end AS BIGINT) > ${timeStart})
        AND NOT (CAST(time_start AS BIGINT) = ${timeStart} AND ${timeEnd} > CAST(time_end AS BIGINT))\n`,
        projection: "time_start, time_end",
        sort: "time_start ASC",
      },
    });

    if (dup.data.ok) {
      if (dup.data.message.length > 0) {
        Swal.fire({
          title: `กรุณาเช็ควันเวลาที่คุณใช้งานอีกครั้ง !`,
          showCloseButton: true,
          icon: "error",
          showConfirmButton: false,
          html: `<div className="flex gap-2">
          <div>วันที่ เวลา</div>
          ${dup.data.message
            .map((x) => {
              let ts = moment(Number(x.time_start)).format("DD/MM/YYYY HH:mm");
              let te = moment(Number(x.time_end)).format("DD/MM/YYYY HH:mm");

              return `<div>${ts} - ${te}</div>`;
            })
            .join("")}
          <div>ถูกจองแล้ว</div>
        </div>`,
        });
      } else {
        let obj = {
          time_start: timeStart,
          time_end: timeEnd,
          ...val,
          travel:
            val.travel === 1 ? "ภายในจังหวัดพิษณุโลก" : "ภายนอกจังหวัดพิษณุโลก",
          user_id: userState.user_id,
          create_at: serverTime.data,
          update_at: serverTime.data,
        };

        if (obj.travel === 2 && obj.agree === "true") {
          Object.keys(arrExpenses).forEach((x) => {
            let check = val.expensesAll.includes(arrExpenses[x]);

            if (check) obj[x] = `${check}`;
          });
        }

        delete obj.datepicker;
        delete obj.timepicker;
        delete obj.expensesAll;

        const res = await postData(
          API_ENDPOINT + `/db/mis_logistic_nu/insert`,
          {
            insert: obj,
            table: "booking_a_car",
          }
        );

        if (res.data.ok) {
          setDefaultForm();

          Swal.fire({
            text: "บันทึกสำเร็จ !",
            showCloseButton: true,
            icon: "success",
            showConfirmButton: false,
          });
        } else {
          console.log(res.data.message);
        }
      }
    } else {
      console.log(dup.data.message);
    }
  };

  const handleSaveLeave = async () => {
    const serverTime = await getData(API_ENDPOINT + "/servertime");

    let val = formBook.getFieldsValue();
    let dupMatch;
    const chooseRanges = [];

    if (radioDate === 1) {
      const chooseDays = val.datepicker.map((x) => {
        let date = moment(x.$d);
        const startDate = date.clone().startOf("day");
        const endDate = date.clone().endOf("day");

        return [startDate.valueOf(), endDate.valueOf()];
      });

      let currentRange = chooseDays[0];

      for (let i = 1; i < chooseDays.length; i++) {
        const nextRange = chooseDays[i];
        const endPlusOne = currentRange[1] + 1;
        const isDay = moment(endPlusOne).format("dddd");
        const isWeekend = ["Saturday", "Sunday"].includes(isDay);

        if (nextRange[0] <= endPlusOne || isWeekend) {
          currentRange[1] = nextRange[1];
        } else {
          chooseRanges.push(currentRange);

          currentRange = nextRange;
        }
      }

      chooseRanges.push(currentRange);

      const dupMap = chooseRanges.map((x) => {
        return `(CAST(time_start AS BIGINT) < ${x[1]} AND CAST(time_end AS BIGINT) > ${x[0]})
      OR (CAST(time_start AS BIGINT) >= ${x[0]} AND CAST(time_end AS BIGINT) <= ${x[1]})
      OR (CAST(time_start AS BIGINT) < ${x[1]} AND CAST(time_end AS BIGINT) > ${x[1]})
      OR (CAST(time_start AS BIGINT) < ${x[0]} AND CAST(time_end AS BIGINT) > ${x[0]})\n`;
      });

      dupMatch = dupMap.join("OR");
    } else {
      let timeStart = moment(val.datepicker[0].$d).valueOf();
      let timeEnd = moment(val.datepicker[1].$d).valueOf();

      chooseRanges.push([timeStart, timeEnd]);

      dupMatch = `(CAST(time_start AS BIGINT) < ${timeEnd} AND CAST(time_end AS BIGINT) > ${timeStart})
      OR (CAST(time_start AS BIGINT) >= ${timeStart} AND CAST(time_end AS BIGINT) <= ${timeEnd})
      OR (CAST(time_start AS BIGINT) < ${timeEnd} AND CAST(time_end AS BIGINT) > ${timeEnd})
      OR (CAST(time_start AS BIGINT) < ${timeStart} AND CAST(time_end AS BIGINT) > ${timeStart})\n`;
    }

    const dup = await postData(API_ENDPOINT + "/db/mis_logistic_nu/select", {
      query: {
        table: "leave",
        match: `user_id = '${userState.user_id}' AND (${dupMatch})`,
        projection: "time_start, time_end",
        sort: "time_start ASC",
      },
    });

    if (dup.data.ok) {
      if (dup.data.message.length > 0) {
        Swal.fire({
          title: `กรุณาเช็ควันที่คุณใช้งานอีกครั้ง !`,
          showCloseButton: true,
          icon: "error",
          showConfirmButton: false,
          html: `<div className="flex gap-2">
            <div>วันที่</div>
            ${dup.data.message
              .map((x) => {
                let ts = moment(Number(x.time_start)).format("DD/MM/YYYY");
                let te = moment(Number(x.time_end)).format("DD/MM/YYYY");

                return `<div>${ts} - ${te}</div>`;
              })
              .join("")}
            <div>คุณได้ลาในระบบแล้ว</div>
          </div>`,
        });
      } else {
        delete val.datepicker;

        if (params.book === "relax") val.leave_name = "พักผ่อน";

        const objMap = chooseRanges.map((x) => {
          let obj = {
            time_start: x[0],
            time_end: x[1],
            ...val,
            user_id: userState.user_id,
            create_at: serverTime.data,
            update_at: serverTime.data,
            scheduled: diffDays(x[0], x[1]),
            type_leave_id: params.book === "sick" ? 2 : 1,
          };

          return obj;
        });

        const res = await postData(
          API_ENDPOINT + `/db/mis_logistic_nu/insert`,
          {
            insert: objMap,
            table: "leave",
          }
        );

        if (res.data.ok) {
          setDefaultForm();

          Swal.fire({
            text: "บันทึกสำเร็จ !",
            showCloseButton: true,
            icon: "success",
            showConfirmButton: false,
          });
        } else {
          console.log(res.data.message);
        }
      }
    } else {
      console.log(dup.data.message);
    }
  };

  const handleSave = async () => {
    setLoading(true);

    if (params.id === "room") {
      await handleSaveRoom();
    } else if (params.id === "car") {
      await handleSaveCar();
    } else {
      await handleSaveLeave();
    }

    setLoading(false);
  };

  useEffect(() => {
    async function init() {
      setLoading(true);

      if (params.id === "room") {
        const msg = await getQuery();

        await getDataCalendar();
        const resFac = await getFacility();

        if (resFac.data.ok) {
          let facMsg = resFac.data.message;

          const data = msg.map((x) => {
            if (x.default_f) {
              const spl = x.default_f.split(",").map((y) => {
                const filterFac = facMsg.filter(
                  (z) => Number(z.id) === Number(y)
                );

                return filterFac[0];
              });

              delete x.default_f;

              x.facility = spl;
            }

            return x;
          });

          setTypes(data);
        } else setTypes(msg);
      } else if (params.id === "car") {
        const msg = await getQuery();

        await getDataCalendar();

        setTypes(msg);
      } else {
        if (params.book === "sick") {
          await getLastLeaveSick();
        } else {
          await getAllLeaveRelex();
        }
      }

      setLoading(false);
    }

    setDefaultForm();
    init();
  }, []);

  return (
    <>
      <LoadingComponent open={loading} />
      {params.book === "follow" ? (
        <AdminPage userid={userState.user_id} mode={params.id} />
      ) : (
        <Card
          type="inner"
          title={filterPath.subpath[params.book].menu}
          headStyle={{ background: "#ccc" }}
          style={{
            boxShadow:
              "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
          }}
        >
          <div className="flex justify-center gap-6">
            <Form
              className="w-1/2 p-6 rounded-md shadow-2xl"
              layout="vertical"
              form={formBook}
              onFinish={(e) => handleSave(e)}
            >
              <>
                {params.id !== "leave" ? (
                  <>
                    <div className="flex justify-bettween gap-6">
                      <Carousel
                        autoplay={auto}
                        autoplaySpeed={5000}
                        ref={carouselRef}
                        className="w-96 p-10 bg-orange-300 rounded-lg shadow-2xl"
                        afterChange={(index) => setSelectRoom(types[index])}
                      >
                        {types.map((x, i) => (
                          <div className="text-center" key={i}>
                            <img src={x.image} />
                          </div>
                        ))}
                      </Carousel>
                      <Card
                        className="w-full bg-blue-200"
                        title=""
                        bordered={false}
                        headStyle={{ background: "#ccc" }}
                        style={{
                          boxShadow:
                            "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                        }}
                      >
                        {params.id === "room" ? (
                          <div className="text-lg">
                            <div className="mb-6 text-center text-xl">
                              ห้อง: {selectRoom?.cr_name}
                            </div>
                            <div className="mb-1">โสตทัศนูปกรณ์ :</div>
                            <div className="flex flex-col gap-1 bg-gray-100 p-2 rounded-md shadow-lg">
                              {selectRoom?.facility ? (
                                selectRoom.facility.map((x) => (
                                  <div
                                    key={x.id}
                                    className="flex gap-2 items-center"
                                  >
                                    <CheckCircleOutlined />
                                    {x.facility_name}
                                  </div>
                                ))
                              ) : (
                                <div className="flex gap-2 items-center">
                                  <CloseCircleOutlined />
                                  ห้องนี้ไม่มีโสตทัศนูปกรณ์
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="text-lg">
                            <div className="mb-6 text-center text-xl">
                              ยี่ห้อ: {selectRoom?.cr_name}
                            </div>
                            <div className="mb-1">ข้อมูล :</div>
                            <div className="flex flex-col gap-1 bg-gray-100 p-2 rounded-md shadow-lg">
                              <div className="flex gap-2 items-center">
                                ทะเบียนรถ : {selectRoom?.car_registration}
                              </div>
                            </div>
                          </div>
                        )}
                      </Card>
                    </div>
                    <Divider />
                  </>
                ) : null}

                {params.id === "room" ? (
                  <>
                    <Form.Item
                      label="ขอความอนุเคราะห์ใช้ห้องประชุมเพื่อ"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก ${label}!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 4 }}
                        placeholder="ระบุการใช้ห้องประชุม"
                      />
                    </Form.Item>

                    <div className="flex gap-4">
                      <Form.Item
                        className="w-1/2"
                        name={"room_id"}
                        label="ห้อง"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก ${label}!",
                          },
                        ]}
                      >
                        <Select
                          defaultValue={""}
                          onChange={(e) => {
                            const index = types.findIndex((x) => x.id === e);
                            carouselRef.current.goTo(index);
                            setAuto(false);
                          }}
                          options={[
                            {
                              value: "",
                              label: "กรุณาเลือก",
                              disabled: true,
                            },
                            ...types.map((x) => ({
                              value: x.id,
                              label: x.cr_name,
                            })),
                          ]}
                        />
                      </Form.Item>

                      <Form.Item
                        className="w-1/2"
                        name="people"
                        label="จำนวนผู้เข้าร่วมประชุม"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก ${label}!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (Number(value) > Number(selectRoom?.size)) {
                                return Promise.reject(
                                  new Error(
                                    `ห้อง ${selectRoom.cr_name} รองรับได้ ${selectRoom.size} คน!`
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="ระบุจำนวนคน"
                          defaultValue="1"
                          min={1}
                          // max={selectRoom?.size}
                        />
                      </Form.Item>
                    </div>

                    <div className="flex gap-4">
                      <Form.Item
                        className="w-full"
                        name="datepicker"
                        label={
                          <div className="flex gap-4">
                            <label>วันที่</label>
                            <label>{"("}</label>
                            <Radio.Group
                              name="radiogroup"
                              defaultValue={1}
                              onChange={(e) => setRadioDate(e.target.value)}
                            >
                              <Radio value={1}>รายวัน</Radio>
                              <Radio value={2}>รายครั้ง</Radio>
                            </Radio.Group>
                            <label className="-ml-4">{")"}</label>

                            {radioDate === 1 ? (
                              <label className="underline">
                                เลือกได้มากกว่า 1 วัน
                              </label>
                            ) : (
                              <label className="underline">
                                ระบบจะตัดวันหยุดเสาร์อาทิตย์ออกอัตโนมัติ
                              </label>
                            )}
                          </div>
                        }
                        rules={[
                          {
                            type: "array",
                            required: true,
                            message: "กรุณากรอก วันที่!",
                          },
                        ]}
                      >
                        {radioDate === 1 ? (
                          <DatePicker
                            format="DD/MM/YYYY"
                            multiple
                            maxTagCount="responsive"
                            disabledDate={disabledDate}
                          />
                        ) : (
                          <DatePicker.RangePicker
                            placeholder={["วันเริ่มต้น", "วันสิ้นสุด"]}
                            className="w-full"
                            format="DD/MM/YYYY"
                            defaultValue={[dayjs(), dayjs()]}
                            disabledDate={disabledDate}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        name="timepicker"
                        label="เวลา"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก เวลา!",
                          },
                        ]}
                      >
                        <TimePicker.RangePicker
                          showTime={{
                            defaultValue: [dayjs(), dayjs()],
                          }}
                          changeOnScroll
                          format={"HH:mm"}
                          placeholder={["เริ่มต้น", "สิ้นสุด"]}
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : params.id === "car" ? (
                  <>
                    <Form.Item
                      label="ขอความอนุเคราะห์ใช้รถเพื่อ"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก ${label}!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 4 }}
                        placeholder="ระบุการใช้ห้องประชุม"
                      />
                    </Form.Item>

                    <div className="flex gap-4">
                      <Form.Item
                        className="w-1/2"
                        name={"car_id"}
                        label="รถ"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก ${label}!",
                          },
                        ]}
                      >
                        <Select
                          defaultValue={""}
                          onChange={(e) => {
                            const index = types.findIndex((x) => x.id === e);
                            carouselRef.current.goTo(index);
                            setAuto(false);
                          }}
                          options={[
                            {
                              value: "",
                              label: "กรุณาเลือก",
                              disabled: true,
                            },
                            ...types.map((x) => ({
                              value: x.id,
                              label: `${x.cr_name} ${x.car_registration}`,
                            })),
                          ]}
                        />
                      </Form.Item>

                      <Form.Item
                        className="w-1/2"
                        name="people"
                        label="จำนวนผู้เดินทาง"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก ${label}!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="ระบุจำนวนคน"
                          defaultValue="1"
                          min={1}
                        />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name="location"
                      label="สถานที่"
                      rules={[
                        { required: true, message: "กรุณากรอก ${label}!" },
                      ]}
                    >
                      <Input placeholder="ระบุสถานที่" />
                    </Form.Item>

                    <Form.Item
                      className="w-1/2"
                      name={"travel"}
                      label={"การเดินทาง"}
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก ${label}!",
                        },
                      ]}
                    >
                      <Select
                        defaultValue={"ภายในจังหวัดพิษณุโลก"}
                        options={[
                          {
                            value: "",
                            label: "กรุณาเลือก",
                            disabled: true,
                          },
                          {
                            value: 1,
                            label: "ภายในจังหวัดพิษณุโลก",
                          },
                          {
                            value: 2,
                            label: "ภายนอกจังหวัดพิษณุโลก",
                          },
                        ]}
                        onChange={(e) => setMode(e)}
                      />
                    </Form.Item>

                    <Form.Item
                      label={"ค่าใช้จ่าย"}
                      name="agree"
                      rules={[
                        { required: true, message: "กรุณากรอก ${label}!" },
                      ]}
                    >
                      <Radio.Group
                        defaultValue={"true"}
                        onChange={(e) => setWherewith(e.target.value)}
                      >
                        <Space direction="horizontal">
                          <Radio value={"true"}>
                            ยินดีรับผิดชอบค่าใช้จ่ายทั้งหมด
                          </Radio>
                          <Radio value={"false"}>
                            ขออนุมัติยกเว้นค่าใช้จ่ายทั้งหมด
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>

                    {wherewith === "false" ? (
                      <Form.Item
                        name="agree_detail"
                        label="เนื่องจาก"
                        rules={[
                          { required: true, message: "กรุณากรอก ${label}!" },
                        ]}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 4 }}
                          placeholder="ระบุ"
                        />
                      </Form.Item>
                    ) : mode === 2 ? (
                      <>
                        <Form.Item
                          label="ดำเนินการเอกสารเกี่ยวกับการเดินทางและเบิกจ่ายทั้งหมด"
                          name="expensesAll"
                          rules={[
                            { required: true, message: "กรุณากรอก ${label}!" },
                          ]}
                        >
                          <Checkbox.Group
                            className="flex-col"
                            options={Object.values(arrExpenses)}
                            onChange={(e) => setExpensesAll(e)}
                          />
                        </Form.Item>
                        {expensesAll.includes("ค่าใช้จ่ายอื่น ๆ") ? (
                          <Form.Item
                            className="-mt-5 ml-5"
                            name="other_detail"
                            rules={[
                              {
                                required: true,
                                message: "กรุณากรอก ${label}!",
                              },
                            ]}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 4 }}
                              placeholder="ระบุค่าใช้จ่ายอื่น ๆ"
                            />
                          </Form.Item>
                        ) : null}
                      </>
                    ) : null}

                    <Form.Item
                      name="datepicker"
                      label="วันที่"
                      rules={[
                        {
                          type: "array",
                          required: true,
                          message: "กรุณากรอก วัน-เวลา!",
                        },
                      ]}
                    >
                      <DatePicker.RangePicker
                        showTime={{
                          defaultValue: [dayjs(), dayjs()],
                        }}
                        disabledDate={disabledDate}
                        placeholder={["วัน-เวลาเริ่มต้น", "วัน-เวลาสิ้นสุด"]}
                        format={"DD/MM/YYYY HH:mm"}
                        className="w-full"
                      />
                    </Form.Item>
                  </>
                ) : params.id === "leave" ? (
                  <>
                    {params.book === "sick" ? (
                      <>
                        <Card
                          bordered={false}
                          className="mb-5 bg-orange-200 text-gray-500 flex justify-center items-center"
                          style={{
                            boxShadow:
                              "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                          }}
                        >
                          <div className="text-center">
                            ประวัติการลา (ไม่รวมวันลาพักผ่อน)
                          </div>
                          {!lastestSick[0] && !lastestSick[1] ? (
                            <div className="text-center">
                              ยังไม่มีวันลาในระบบ
                            </div>
                          ) : (
                            <div className="text-center">
                              {"ลาครั้งสุดท้ายเมื่อ "}
                              <span className="underline">
                                {lastestSick[0]}
                              </span>
                              {" ถึง "}
                              <span className="underline">
                                {lastestSick[1]}
                              </span>
                            </div>
                          )}
                        </Card>

                        <Form.Item
                          name="leave_name"
                          label="ขอลา"
                          rules={[
                            { required: true, message: "กรุณากรอก ${label}!" },
                          ]}
                        >
                          <Radio.Group
                            defaultValue="1"
                            onChange={(e) => setWherewith(e.target.value)}
                          >
                            <Space direction="horizontal">
                              {Object.keys(arrLeave).map((x) => (
                                <Radio value={x}>{arrLeave[x]}</Radio>
                              ))}
                            </Space>
                          </Radio.Group>
                        </Form.Item>

                        {wherewith === "กิจส่วนตัว" ? (
                          <Form.Item
                            label="เนื่องจาก"
                            name="personal_affairs_detail"
                            rules={[
                              {
                                required: true,
                                message: "กรุณากรอก ${label}!",
                              },
                            ]}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 4 }}
                              placeholder="ระบุ"
                            />
                          </Form.Item>
                        ) : null}
                      </>
                    ) : params.book === "relax" ? (
                      <div className="mb-5 flex gap-4 w-full">
                        <Card
                          bordered={false}
                          className="w-1/4 bg-orange-200"
                          style={{
                            boxShadow:
                              "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                          }}
                        >
                          <Statistic
                            className="text-white"
                            title="วันลาพักผ่อนสะสม"
                            value={LEAVE_CNT.person}
                            valueStyle={{
                              color: "#3f8600",
                            }}
                          />
                        </Card>
                        <Card
                          bordered={false}
                          className="w-1/4 bg-orange-200"
                          style={{
                            boxShadow:
                              "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                          }}
                        >
                          <Statistic
                            title="สิทธิลาพักผ่อนประจำปีนี้"
                            value={LEAVE_CNT.year}
                            valueStyle={{
                              color: "#3f8600",
                            }}
                          />
                        </Card>
                        <Card
                          bordered={false}
                          className="w-1/4 bg-blue-200"
                          style={{
                            boxShadow:
                              "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                          }}
                        >
                          <Statistic
                            title="ลาไปแล้ว"
                            value={hisState.total}
                            valueStyle={{
                              color: "#3f8600",
                            }}
                          />
                        </Card>
                        <Card
                          bordered={false}
                          className="w-1/4 bg-green-200"
                          style={{
                            boxShadow:
                              "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                          }}
                        >
                          <Statistic
                            title="คงเหลือ"
                            value={
                              LEAVE_CNT.person + LEAVE_CNT.year - hisState.total
                            }
                            valueStyle={{
                              color: "#3f8600",
                            }}
                          />
                        </Card>
                      </div>
                    ) : null}

                    <Form.Item
                      label="ในระหว่างลาจะติดต่อข้าพเจ้าได้ที่"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก ${label}!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 4 }}
                        placeholder="ระบุ"
                      />
                    </Form.Item>

                    <Form.Item
                      name="datepicker"
                      label={
                        <div className="flex gap-4">
                          <label>วันที่</label>
                          <label>{"("}</label>
                          <Radio.Group
                            name="radiogroup"
                            defaultValue={1}
                            onChange={(e) => setRadioDate(e.target.value)}
                          >
                            <Radio value={1}>รายวัน</Radio>
                            <Radio value={2}>รายครั้ง</Radio>
                          </Radio.Group>
                          <label className="-ml-4">{")"}</label>

                          {radioDate === 1 ? (
                            <label className="underline">
                              เลือกได้มากกว่า 1 วัน
                            </label>
                          ) : (
                            <label className="underline">
                              ระบบจะตัดวันหยุดเสาร์อาทิตย์ออกอัตโนมัติ
                            </label>
                          )}
                        </div>
                      }
                      rules={[
                        {
                          type: "array",
                          required: true,
                          message: "กรุณากรอก วันที่!",
                        },
                      ]}
                    >
                      {radioDate === 1 ? (
                        <DatePicker
                          format="DD/MM/YYYY"
                          multiple
                          maxTagCount="responsive"
                          disabledDate={
                            params.book === "sick" &&
                            (wherewith === "ป่วย" || wherewith === "คลอดบุตร")
                              ? false
                              : disabledDate
                          }
                        />
                      ) : (
                        <DatePicker.RangePicker
                          placeholder={["วันเริ่มต้น", "วันสิ้นสุด"]}
                          className="w-full"
                          format="DD/MM/YYYY"
                          defaultValue={[dayjs(), dayjs()]}
                          disabledDate={
                            params.book === "sick" &&
                            (wherewith === "ป่วย" || wherewith === "คลอดบุตร")
                              ? false
                              : disabledDate
                          }
                        />
                      )}
                    </Form.Item>
                  </>
                ) : null}

                <div className="w-full text-right mt-12">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="bg-blue-500 w-1/2"
                  >
                    บันทึก
                  </Button>
                </div>

                {params.id === "room" ? (
                  <>
                    <Divider />
                    <Alert
                      message="หมายเหตุ"
                      description={
                        <div className="flex flex-col gap-2">
                          <>
                            <p>
                              * คณะฯ เปิดให้บริการห้องแก่บุคลากรภายนอก เฉพาะห้อง
                              201 202 และ 301 เท่านั้นส่วนห้องอื่นๆ
                              จะให้บริการสำหรับจัดการเรียนการสอนของคณะฯ *
                            </p>
                            <p>
                              ** ทั้งนี้
                              ผู้ขอใช้ยินดีรับผิดชอบในกรณีที่เกิดความเสียหายต่ออุปกรณ์หรือค่าปฏิบัติงานนอกเวลาราชการ
                              **
                            </p>
                          </>
                        </div>
                      }
                      type="warning"
                      showIcon
                    />
                  </>
                ) : params.id === "car" ? (
                  <>
                    <Divider />
                    <Alert
                      message="หมายเหตุ"
                      description={
                        <div className="flex flex-col gap-2">
                          <p>
                            * คณะฯ เปิดให้บริการรถแก่บุคลากรภายนอก เฉพาะรถตู้
                            (Toyota) เท่านั้น *
                          </p>
                        </div>
                      }
                      type="warning"
                      showIcon
                    />
                  </>
                ) : null}
              </>
            </Form>

            {params.id !== "leave" ? (
              <Calendar
                className="w-1/2 rounded-md shadow-2xl h-fit p-4"
                cellRender={cellRender}
                mode={modeCalendar}
                onPanelChange={(_, m) => setModeCalendar(m)}
                onSelect={async (e, { source }) => {
                  if (
                    source === "date" &&
                    modeCalendar === "month"
                    // || (source === "month" && modeCalendar === "year")
                  ) {
                    let content = dateDrawerRender(e);
                    let title = moment(e.$d).format("DD/MM/YYYY");

                    setDrawerContent({
                      open: true,
                      content: content,
                      title: "วันที่ " + title,
                    });
                  } else if (source === "month" && modeCalendar === "month") {
                    setLoading(true);
                    await getDataCalendar(e);
                    setLoading(false);
                  }
                }}
              />
            ) : null}
          </div>
        </Card>
      )}

      <Drawer
        title={drawerContent.title}
        placement="left"
        size={"large"}
        onClose={() => {
          setDrawerContent({ open: false });
        }}
        open={drawerContent.open}
        extra={
          <Space>
            <Button
              type="primary"
              danger
              onClick={() => {
                setDrawerContent({ open: false });
              }}
            >
              ปิด
            </Button>
          </Space>
        }
      >
        <div>{drawerContent.content}</div>
      </Drawer>
    </>
  );
};

export default BookingPage;
